// BUTTONS

.btn {
  display: block;
  font-family: var(--font-alegreya);
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  padding: 0.4rem 1rem;
  cursor: pointer;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &.full-width {
    width: 100%;
  }

  &.custom-width {
    width: 46%;
  }

  &.lowercase {
    text-transform: none;
  }

  &.medium {
    font-size: 0.9rem;
  }

  &.small {
    font-size: 0.8rem;
    padding: 0.2rem 0.8rem;
  }

  &.filter {
    font-size: 0.775rem !important;
    font-weight: 500;
    padding: 0.25rem 1rem !important;
  }

  &:disabled{
    background-color: #9b9b9b !important;
    border: 1px solid #9b9b9b !important;
  }
}

.btn-black {
  border: 1px solid black;
  color: white;
  background-color: black;
}

.btn-black-hover-white {
  border: 1px solid black;
  color: white;
  background-color: black;

  &:hover {
    color: black;
    background-color: white;
  }
}

.btn-black-hover-light {
  border: 1px solid black;
  color: white;
  background-color: black;

  &:hover {
    border: 1px solid rgb(34, 34, 34);
    background-color: rgb(34, 34, 34);
    color: white;
  }
}

.btn-white {
  border: 1px solid black;
  color: black;
  background-color: white;
}

.btn-white-hover-border {
  @extend .btn-white;
  &:hover {
    border: 2px solid black;
    margin-top: -1px;
    margin-bottom: -1px;
  }
}

.btn-white-hover-black {
  border: 1px solid black;
  color: black;
  background-color: white;

  &:hover {
    color: white;
    background-color: black;
  }
}

.btn-grey-hover-black {
  border: 1px solid #f3f3f3;
  color: black;
  background-color: #f3f3f3;
  text-transform: none;

  &:hover {
    @media (min-width: 992px) {
      border: 1px solid black;
      color: white;
      background: black;
    }
  }
}

// END OF BUTTONS

// LINKS

.link {
  text-decoration: none;
  color: black;
  line-height: 1.2;
  border-bottom: 1px solid transparent;

  &:visited,
  &:hover {
    color: black;
  }

  &:hover {
    border-bottom: 1px solid #000;
  }

  &.link-active {
    font-weight: 700;
    border-bottom: 1px solid #000;
  }

  &.link-active.link-red {
    border-bottom: 1px solid red;
  }

  &.link-active-bold {
    font-weight: 700;
  }

  &.link-uppercase {
    text-transform: uppercase;
  }

  &.link-underline {
    border-bottom: 1px solid #000;
  }

  &.link-red {
    color: red;

    &:hover {
      border-bottom: 1px solid red;
    }
  }
}
