.dropdown {
  .button {
    border-bottom: 1px solid black;
    z-index: 61;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    width: 175px;
    float: right;
  }

  .list {
    position: absolute;
    z-index: 61;
    background: white;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    width: 175px;
    right: 15px;
    top: 33px;
  }

  .list-item {
    font-family: var(--font-alegreya);
    font-size: 0.875rem;
    line-height: 1.0625rem;
    white-space: nowrap;
    display: block;
    color: black;

    &:hover {
      cursor: pointer;
      color: white !important;
      background-color: black !important;
    }
  }

  // USING FONTAWESOME CHEVRON ICON WITH ALL THESE FUNCTIONALITIES
  //.icon {
  //  width: 15px;
  //  height: 7px;
  //  float: right;
  //  margin-top: 4px;
  //  align-self: center;
  //  transition: transform 0.3s;

  //&.arrow-black {
  //  background: url(/intstatic/icons/black-arrow-up.svg) center center no-repeat;
  //}
  //
  //&.arrow-white {
  //  background: url(/intstatic/icons/white-arrow-up.svg) center center no-repeat;
  //}

  //&.down {
  //  transform: rotate(180deg);
  //}
  //}
}
