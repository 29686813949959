// For more bootstrap imports checkout root bootstrap scss file
// node_modules/bootstrap/scss/bootstrap.scss

// Configuration
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/mixins';
// https://github.com/twbs/bootstrap/issues/36785#issuecomment-1189969247
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/utilities';

// Layout & components
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';

// Helpers
@import 'bootstrap/scss/helpers';

// Utilities
@import 'bootstrap/scss/utilities/api';

// Custom scss import bellow
// @import 'path-to-custom.scss'
@import 'src/styles/buttons.scss';
@import 'src/styles/dropdown.scss';
//@import './fonts';

body {
  font-family: var(--font-alegreya);
  font-size: 1rem;
  overscroll-behavior: none;
}

.container {
  max-width: 93.75rem !important;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  @media (max-width: 768px) {
    padding-left: 0.938rem;
    padding-right: 0.938rem;
  }
}

.container-60 {
  max-width: 60rem !important;
  width: 100%;
}

input:focus {
  outline: none;
}

/* clears the ‘X’ on search boxes (Marke search) -> Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ on search boxes (Marke search) -> Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

// hide up and down arrows on number input fields
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// hide placeholder on focus
input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */
input:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */
input:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */

.text-overflow-hidden {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-overflow-scroll {
  overflow: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.no-scrollbar {
    &::-webkit-scrollbar {
      display: none;
      height: 0;
    }
  }
}

// Homepage headline
.headline {
  font-size: 1.6rem;
  font-family: var(--font-playfair);
  font-style: italic;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 1.0625rem;
    max-width: 18.75rem;
    margin-right: auto;
    margin-left: auto;
  }
}

.fs-7 {
  font-size: 0.625rem;
}

//List page
ul.sidebar {
  list-style-type: none;
  padding: 0;

  li {
    margin-left: 1.25rem;
  }
}

.sticky-sidebar {
  top: 1rem;
  position: sticky;

  .link:hover {
    border-bottom: 1px solid transparent;
  }

  .sidebar-list {
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      width: 0.375rem;
      height: 0.312rem;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d8d8d8;
      border-radius: 2px;
    }
  }
}

.link-sidebar {
  margin-bottom: 0.525rem;
  display: block;
}

.line-height-1 {
  line-height: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.button-gender {
  font-size: 1.125rem;
}

//navigation
.main-links {
  padding-bottom: 0.125rem;
  margin-right: 1.25rem;
  font-size: 1.125rem;
  cursor: pointer;

  &.selected {
    border-color: black;
  }

  &:hover {
    border-color: black;
  }
}
.link-red {
  color: red;
  &.selected {
    border-color: red !important;
  }
}
.sub-links {
  padding-bottom: 0.125rem;
  margin-right: 1.562rem;
  font-size: 1.125rem;
  border-bottom: 1px solid transparent;

  &.selected {
    border-color: black;
  }

  &:hover {
    border-color: black;
  }
}

.main-headline {
  font-family: var(--font-alegreya);
  font-weight: bold;
  font-size: 1.5rem;
  @media (max-width: 992px) {
    font-size: 1.25rem;
  }
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #649b8d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #fff, 0 0 5px #fff;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

//Error Page
.mainWrapper {
  @media (min-width: 992px) {
    margin-top: 4.6875rem;
    margin-bottom: 8.8125rem;
  }
}

.txtWrapper {
  margin-top: 2.375rem;
  @media (min-width: 576px) and (max-width: 1300px) {
    margin-top: 4rem;
  }
  @media (min-width: 1300px) {
    margin-top: 8.0625rem;
  }
}

.errHeadline {
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 200;
  text-align: center;
  font-family: var(--font-playfair);
  margin-bottom: 2.0625rem;
  width: 672px;
  @media (min-width: 576px) and (max-width: 1024px) {
    font-size: 1.85rem;
  }
  @media (max-width: 576px) {
    font-size: 1.25rem;
    font-style: italic;
    margin-bottom: 1.125rem;
    width: 245px;
  }
}

.text {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 4.125rem;
  width: 602px;
  @media (min-width: 576px) and (max-width: 1024px) {
    font-size: 1.125rem;
  }
  @media (max-width: 576px) {
    font-size: 0.75rem;
    margin-bottom: 1.9375rem;
    line-height: 1.5;
    width: 250px;
  }
}

.btn-error {
  height: 3.4375rem;
  width: 16.1875rem;
  font-size: 1rem !important;
  @media (max-width: 576px) {
    height: 2.25rem;
    width: 8.75rem;
    font-size: 0.875rem !important;
  }
}

.seo-text {
  font-size: 0.9rem;
  h2 {
    font-size: 1rem;
  }
  a {
    color: black;
    @media (max-width: 576px) {
      display: inline-block;
    }
  }
  a,
  p,
  span {
    font-family: 'Alegreya Sans' !important;
  }
}

// START -- CUSTOM CHECKBOX ON FILTERS - NICE-TO-HAVE REQUIREMENT FROM INT-542
/* Basic styling */
[type='checkbox'] {
  width: 0.8rem;
  height: 0.8rem;
  color: black;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  border-radius: 0;
  background-color: #ffffff;
  transition: background 300ms;
  cursor: pointer;
}

/* Pseudo element for check styling */
[type='checkbox']::before {
  content: '';
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #000000;
}

/* Checked */
[type='checkbox']:checked {
  background-color: currentcolor;
}

[type='checkbox']:checked::before {
  box-shadow: none;
  background-image: url('/intstatic/icons/checkmark.svg');
}

/* IE */
[type='checkbox']::-ms-check {
  content: '';
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #ccd3d8;
}

[type='checkbox']:checked::-ms-check {
  box-shadow: none;
  background-image: url('/intstatic/icons/checkmark.svg');
}

.filter-list div:first-of-type {
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 5px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 2px;
  }
}

// END -- CUSTOM CHECKBOX

//wp about pages
.two_col {
  @media (min-width: 767px) {
    column-count: 2;

    p {
      text-align: start !important;
    }
  }
}

.aligncenter {
  align-items: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#cmpLink {
  text-decoration: underline;
  cursor: pointer;
}

.disabled {
  color: #cfcfcf;
}

.cat-active-bold {
  font-size: 1.125rem;
  font-weight: bold;
  padding: 0.625rem 0 0 1.25rem;
  text-transform: capitalize;
}

.cat-back {
  font-size: 1.125rem;
  padding: 0.625rem 0;
  text-transform: capitalize;

  svg {
    height: 0.812rem;
  }
}

.mbb-menu-user-section-btns {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
  height: 60px;
  justify-content: space-around;
  border-bottom: 1px solid #cecece;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09), 0 2px 10px 0 rgba(0, 0, 0, 0.09);
  margin-bottom: 10px;
}

.mbb-menu-link {
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #000;
  border: none;
  width: 43%;
  margin: 10px 0;
  align-items: center;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.mbb-menu-btn {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #000;
  border: 1px solid #000;
  width: 43%;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.mbb-svg-icons {
  height: 1.5rem;
  width: 1.5rem;
  display: inline-block;
}

.mbb-svg-avatar {
  background: url('/home-living/intstatic/images/avatar-55px.svg') no-repeat center center;
}

.mbb-menu-user-section-avatar {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
  justify-content: left;
  align-items: center;
  z-index: 2 !important;
  padding-left: 1.9rem;
}

.mbb-menu-user-section-avatar span {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 10px;
}

#beamerSelector,
.beamer_icon {
  display: none !important;
}

[id^='groove-container'] {
  z-index: 1 !important;
}

.brand-btn {
  border: 1px solid black;
  padding: 0.312rem 1.25rem;
  width: 9.375rem;
  text-align: center;
  text-decoration: none;
}

.desktop-mode-btn {
  color: black;

  &:hover {
    color: white;
    background: black;
  }
}

.desktop-home-btn {
  color: white;
  background: black;
  pointer-events: none;

  &:hover {
    color: white;
    background: black;
  }
}

.brand-btn-mobile {
  width: 95%;
  margin-left: 0.625rem;
  padding-left: 0;
  font-size: 12px;
}

.mobile-mode-btn {
  color: black;
  text-decoration: none;
  margin-right: 1rem;

  &:hover {
    color: black;
  }
}

.mobile-home-btn {
  border-bottom: 2px solid #000;
  margin: 0 1.562rem;
  font-weight: bold;
}

/* Shimmer effect for the image */
.shimmer-image {
  background: linear-gradient(-45deg, #eee 25%, #ddd 50%, #eee 75%);
  background-size: 200% 200%;
  animation: shimmer_2s_infinite 1.5s linear infinite;
}

/* Shimmer effect for text */
.shimmer-text {
  width: 70%;
  height: 12px;
  background: linear-gradient(-45deg, #eee 25%, #ddd 50%, #eee 75%);
  background-size: 200% 200%;
  animation: shimmer_2s_infinite 1.5s linear infinite;
}

/* Shimmer effect for icon */
.shimmer-icon {
  width: 12px;
  height: 12px;
  background: linear-gradient(-45deg, #eee 25%, #ddd 50%, #eee 75%);
  background-size: 200% 200%;
  animation: shimmer_2s_infinite 1.5s linear infinite;
}

/* Shimmer effect for general use (e.g., background) */
.shimmer {
  background: linear-gradient(-45deg, #eee 25%, #ddd 50%, #eee 75%);
  background-size: 200% 200%;
  animation: shimmer_2s_infinite 1.5s linear infinite;
}

@keyframes shimmer_2s_infinite {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.backTo {
  color: black;
  text-decoration: none;
}

.brandName {
  font-size: 1.125rem;
  line-height: 1.687rem;
}

.productPrice {
  font-size: 1.5rem;
}

.breadcrumbs {
  @media (max-width: 768px) {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }
}

.beamerAnnouncementBar {
  font-family: var(--font-alegreya) !important;
  font-weight: 400 !important;
}

.checkbox-span {
  margin-top:-5px;
}

.btn-follow {
  border-radius: 0.56px;
  border: 0.56px solid #979797;
  color: #979797;
  font-size: 0.687rem;
  line-height: 0.562rem;
  margin-left: 1.25rem;
  margin-top: 0.187rem;
}

.btn-follow.btn-black-hover-light {
  color: white!important;
}

.hide-beamer {
  display: none;
}